import ic_dashboard from './ic_dashboard.svg';
import ic_reward from './ic_reward.svg';
import ic_shop from './ic_shop.svg';
import ic_booking from './ic_booking.svg';
import ic_booking2 from './ic_booking2.svg';

import ic_invite_customer from './ic_invite_customer.svg';
import ic_admin_manage from './ic_admin_manage.svg';
import ic_report from './ic_report.svg';
import ic_tutorial from './ic_tutorial.svg';
import ic_contact_us from './ic_contact_us.svg';
import ic_deposit from './ic_deposit.svg';
import ic_history_order from './ic_history_order.svg';
import ic_history_point from './ic_history_point.svg';

import ic_shopping_cart from './ic_shopping_cart.svg';
import ic_shop_reward from './ic_shop_reward.svg';
import ic_member_manage from './ic_member_manage.svg';
import ic_package from './ic_package.svg';
import ic_coupon from './ic_coupon.svg';
import small_plus from './small_plus.svg';

export const icon = {
  ic_dashboard,
  ic_reward,
  ic_shop,
  ic_booking,
  ic_booking2,
  ic_invite_customer,
  ic_admin_manage,
  ic_report,
  ic_tutorial,
  ic_contact_us,
  ic_deposit,
  ic_history_order,
  ic_history_point,
  ic_shopping_cart,
  ic_shop_reward,
  ic_member_manage,
  ic_package,
  ic_coupon,
  small_plus
};
