import React from 'react';
import { icon } from '../pages/home/assets';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const ShoppingCartButton = ({ top, marginTop, match }) => {
  const history = useHistory();
  const { user, shop } = useSelector(state => state.auth);
  const { items } = useSelector(state => state.shoppingCart);
  const cart = useSelector(state => state.shoppingCart);

  if (!shop || !user) return;
  if (!shop.enableShoppingCart) return;
  if (cart.items.length === 0) return;

  if (user.type !== 'customer' && !cart.targetCustomer) return;

  return (
    <>
      <Container top={top} marginTop={marginTop} onClick={() => history.push(`/shop/${user.seqId}/checkout`)}>
        {items.length > 0 && <ItemIcon>{items.length}</ItemIcon>}
        <div>
          <img src={icon.ic_shopping_cart} height={27} />
          <div className="text-danger">{cart.targetCustomer?.user.firstName}</div>
          {cart.targetBranch && <div className="text-danger">{cart.targetBranch.name}</div>}
        </div>
      </Container>
    </>
  );
};

export default ShoppingCartButton;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  height: 96px;
  width: 96px;
  right: 12px;
  margin-top: ${props => props.marginTop || '10px'};
  cursor: pointer;
  ${props => (props.top ? `top: ${props.top};` : '')}
`;

const ItemIcon = styled.div`
  background: #ff0000;
  position: fixed;
  height: 17px;
  width: 17px;
  color: #ffffff;
  font-weight: 500;
  font-size: 10px;
  line-height: 25px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 16px;
  margin-bottom: 20px;
`;
