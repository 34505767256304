import status_cancel_icon from '../../assets/icons_status/status_cancel_icon.svg';
import status_confitm_icon from '../../assets/icons_status/status_confitm_icon.svg';
import status_waiting_icon from '../../assets/icons_status/status_waiting_icon.svg';

export const OrderStatus = {
  draft: 'draft',
  waitSelectPayment: 'wait-select-payment',
  waitSlip: 'wait-slip',
  waitAddress: 'wait-address',
  waitConfirm: 'wait-confirm',
  waitShipping: 'wait-ship',
  waitCodCash: 'wait-cod-cash',
  reject: 'reject',
  success: 'success',
  cancel: 'cancel'
};

export const listOrderStatus = [
  'draft',
  'wait-select-payment',
  'wait-slip',
  'wait-address',
  'wait-confirm',
  'wait-ship',
  'success',
  'cancel',
  'reject',
  'wait-cod-cash'
];

export const mapOrderStatus = status => {
  if (!status) return 'undefined';

  if (status === OrderStatus.waitSlip) {
    return 'status.waiting.slip';
  } else if (status === OrderStatus.waitAddress) {
    return 'status.waiting.address';
  } else if (status === OrderStatus.waitConfirm) {
    return 'status.waiting.confirm.order';
  } else if (status === OrderStatus.waitShipping) {
    return 'status.waiting.shipping';
  } else if (status === OrderStatus.waitCodCash) {
    return 'status.waiting.cod.cash';
  } else if (status === OrderStatus.success) {
    return 'status.success';
  } else if (status === OrderStatus.cancel) {
    return 'status.cancel';
  } else if (status === OrderStatus.reject) {
    return 'status.reject';
  } else if (status === OrderStatus.waitSelectPayment) {
    return 'status.waiting.select.payment';
  } else if (status === OrderStatus.draft) {
    return 'status.draft';
  }
  return 'undefined';
};

export const mapOrderStatusIcon = status => {
  if (!status) return status_waiting_icon;

  switch (status) {
    case OrderStatus.waitSlip:
      return status_waiting_icon;
    case OrderStatus.waitAddress:
      return status_waiting_icon;
    case OrderStatus.waitConfirm:
      return status_waiting_icon;
    case OrderStatus.waitShipping:
      return status_waiting_icon;
    case OrderStatus.waitCodCash:
      return status_waiting_icon;
    case OrderStatus.success:
      return status_confitm_icon;
    case OrderStatus.cancel:
      return status_cancel_icon;
    case OrderStatus.reject:
      return status_cancel_icon;
    case OrderStatus.waitSelectPayment:
      return status_waiting_icon;
    case OrderStatus.draft:
      return status_waiting_icon;
    default:
      return undefined;
  }
};

export const mapOrderStatusIconBooking = status => {
  if (!status) return status_waiting_icon;

  if (status === 'cf') {
    return status_confitm_icon;
  } else if (status === 'cc') {
    return status_cancel_icon;
  } else if (status === 'wc') {
    return status_waiting_icon;
  }
};

export const mapOrderStatusIconBooking2 = status => {
  if (!status) return status_waiting_icon;

  if (status === 'confirmed') {
    return status_confitm_icon;
  } else if (status === 'cancel') {
    return status_cancel_icon;
  } else if (status === 'waiting') {
    return status_waiting_icon;
  }
};
